/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import React, { useState } from 'react';
import {
  Box,
  Typography,
  AppBar,
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';
import { AiFillCaretDown } from 'react-icons/ai';
import { IoLogOutOutline } from 'react-icons/io5';
import Login from '../Login';

const useStyle = makeStyles((theme) => ({
  rwad: {
    fontFamily: 'Cairo',
  },
  logo: {
    height: 49,
    width: 61,
    margin: theme.spacing(1),
  },
  dropIcon: {
    color: theme.palette.common.white,
    fontSize: theme.spacing(2),
  },
  dropDown: {
    marginTop: theme.spacing(3),
  },
  user: {
    fontFamily: 'Cairo',
    padding: theme.spacing(0.2, 1),
    backgroundColor: '#00000030',
  },
}));

function TopBar() {
  const username = sessionStorage.getItem('admin');
  const [state, setState] = useState(false);
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    setAnchorEl(null);
    sessionStorage.removeItem('admins-jwt');
    sessionStorage.removeItem('admin');
    setState(true);
  };
  if (!state)
    return (
      <Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.dropDown}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem onClick={handleLogOut}>
            <Button startIcon={<IoLogOutOutline />}>الخروج</Button>
          </MenuItem>
        </Menu>
        <AppBar position="static">
          <Box display="flex" justifyContent="space-between">
            <img
              className={classes.logo}
              src="./assets/logoWhite.png"
              alt="logo"
            />
            <Box display="flex" className={classes.rwad} alignItems="center">
              <Typography className={classes.user}>{username}</Typography>
              <IconButton onClick={handleClick}>
                <AiFillCaretDown className={classes.dropIcon} />
              </IconButton>
            </Box>
          </Box>
        </AppBar>
      </Box>
    );
  setTimeout(1000);
  return <Login />;
}

export default TopBar;
