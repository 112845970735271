/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable */

import React, { useState, useEffect, useReducer } from 'react';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
// Material UI
import { DataGrid } from '@material-ui/data-grid';
import Labels from './RequestLabels';
import CountryList from './CountryList';
import {
    Box,
    makeStyles,
    Paper,
    Button,
    InputBase,
    Backdrop,
    CircularProgress,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableRow,
    DialogContent,
    TextField,
    DialogActions,
    Snackbar,
    Link
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close';
import { BiSearch } from 'react-icons/bi';
import MuiAlert from "@material-ui/lab/Alert";
import { utils, writeFile } from 'xlsx';
// CRUD

const getAllRequests = gql`
  query QueryCenterRegistration {
    centerCreationRequests {
      id
      ProjectName
      ProjectOwnerName
      subdomain
      status
      createdAt
      center { info { name }}
    }
  }
`;
const getAllRequestsForExport = gql`
query QueryCenterRegistration {
    centerCreationRequests {
        id
        ProjectName
        ProjectOwnerName
        Address
        Phone
        Town
        Country
        Email
        Website
        IsProjectReady
        ProjectFeatures
        ProjectDisadvantages
        ProjectBarriers
        ProjectType
        FundingSource
        IsLegalState
        WhyIsProjectNotLegal
        HaveManagementExperience
        WhyNotHavingManagementExperience
        TargetCustomers
        TargetAges
        TargetGender
        TargetCertifications
        TargetSalaries
        ForeignCountryBusiness
        ForeignCountry
        ExpectationsFromRwad
        HavingSpecialCurriculumInLeadershipTraining
        subdomain
        createdAt
        status
        rejectReason
        initialSubscriptionPlan{
          id
          name
          slug
        }
        ProjectPlan{url}
        owner{
          id
          username
        }
        center{
            id
            info {
                name
            }
        }
    }
  }
`;
const getRequestDetails = gql`
query getCenterRequest($id:ID!) { 
  centerCreationRequest(id:$id){
    id
    ProjectName
    ProjectOwnerName
    Address
    Phone
    Town
    Country
    Email
    Website
    IsProjectReady
    ProjectFeatures
    ProjectDisadvantages
    ProjectBarriers
    ProjectType
    FundingSource
    IsLegalState
    WhyIsProjectNotLegal
    HaveManagementExperience
    WhyNotHavingManagementExperience
    TargetCustomers
    TargetAges
    TargetGender
    TargetCertifications
    TargetSalaries
    ForeignCountryBusiness
    ForeignCountry
    ExpectationsFromRwad
    HavingSpecialCurriculumInLeadershipTraining
    subdomain
    createdAt
    status
    rejectReason
    initialSubscriptionPlan{
      id
      name
      slug
    }
    ProjectPlan{url}
    owner{
      id
      username
    }
    center { id info { id name }}
  }
}
`;

const CopyRequestToCenterInfo = gql`
mutation copyRequestToCenterInfo($data:CenterInput!){
  createCenter(input:{data:$data}){
    center{id}
  }
}
`;
const CopyRequestToCentersList = gql`
mutation copyRequestToCenterList($data:CentersListInput!){
  createCentersList(input:{data:$data}){
    centersList{id}
  }
}
`;
const updateRequest = gql`
mutation updateRequest($id:ID!,$status:ENUM_CENTERCREATIONREQUEST_STATUS!,$rejectReason:String){
  updateCenterCreationRequest(input:{where:{id:$id},data:{status:$status, rejectReason:$rejectReason}}){centerCreationRequest{id}}
}
`;
// STYLES

const useStyle = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(5),
    },
    dataGrid: {
        cursor: 'pointer',
    },
    loadingStatus: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 2),
        margin: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.25),
            cursor: 'pointer',
        },
        marginLeft: theme.spacing(2),
        marginRight: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'relative',
        left: 0,
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    inputRoot: {
        color: 'inherit',
        fontFamily: 'Cairo',
        marginLeft: theme.spacing(2),
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    head: {
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 700,
        },
    },

    textField: {
        margin: theme.spacing(2, 0),
    },
    banButton: {
        margin: theme.spacing(2, 0),
    },
    cell: {
        '& .MuiDataGrid-cellRight': {
            textAlign: 'right !important',
        },
    },
    title: {
        float: 'right',
    },
}));

function CentersRequests() {
    // const [query, setQuery] = useState(true);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [alertFailure, setAlertFailure] = useState(false);
    const [open, setOpen] = useState(false);
    const [rejectReason, setRejectReason] = useState(false);
    const [approve, setApprove] = useState(false);
    const [reject, setReject] = useState(false);
    const { loading: loadingQueryAll, data: dataQueryAll, refetch: refetchAll } = useQuery(getAllRequests, {
        onError: (err) => console.log('error when querying centers requests', err),
        onCompleted: (d) => console.log('Get All requests succeeded'),
    });
    let [dataToBeExported, { loading: loadingQueryAllForExport, data: dataQueryAllForExport }] = useLazyQuery(getAllRequestsForExport, {
        onError: (err) => console.log('error when querying centers requests', err),
        onCompleted: () => {
            console.log(dataQueryAllForExport.centerCreationRequests);
            exportData(dataQueryAllForExport.centerCreationRequests);
        },
    });
    const exportData = (data) => {
        var wb = utils.book_new();
        var ws = utils.json_to_sheet(data);

        utils.book_append_sheet(wb, ws, "Center Requests");

        writeFile(wb, "MyExport.xlsx");
    }
    let [RequestDetails, { loading: loadingRequestDetails, data: dataRequestDetails, refetch: refetchRequests }] = useLazyQuery(getRequestDetails, {
        onError: (err) => console.log('error when query requests details', err),
        onCompleted: (data) => {
            window.dataRequestDetails = data
            console.log('Get request details succeeded', data);
            setOpen(true);
        },
    });
    const handleClickRequest = (id) => {
        RequestDetails({ variables: { id: id } })
    };

    const handleClose = () => {
        setOpen(false);
    };
    const confirmRequest = () => {
        if (dataRequestDetails.centerCreationRequest.status === 'PENDING') {
            handleClickApprove();
        }
        if (!dataRequestDetails.centerCreationRequest.center) {
            callCreateRequestToCenterList();
        } else if (!dataRequestDetails.centerCreationRequest.center.info) {
            callCreateRequestToCenterInfo(dataRequestDetails.centerCreationRequest.center.id);
        }

        updateRequestStatus({ variables: { id: dataRequestDetails.centerCreationRequest.id, status: 'APPROVED' } });
        setOpen(false);
    }
    const [CreateRequestToCenterInfo, { loading: loadingCreateCenterInfo, data: createCenterInfo }] = useMutation(CopyRequestToCenterInfo, {
        onError: (err) => console.log('error when copying to center info', err),
        onCompleted: (data) => {
            console.log('copied with success')
        }
    });
    const [createRequestToCentersList, { loading: loadingCreateCentersList, data: createCentesList }] = useMutation(CopyRequestToCentersList, {
        onError: (err) => console.log('error when copying to centers list', err),
        onCompleted: (data) => {
            callCreateRequestToCenterInfo(data.createCentersList?.centersList?.id);
            refetchAll();
        }
    });

    const callCreateRequestToCenterInfo = (centerID) => {
        CreateRequestToCenterInfo({
            variables: {
                data: {
                    name: dataRequestDetails.centerCreationRequest.ProjectName,
                    country: dataRequestDetails.centerCreationRequest[
                        dataRequestDetails.centerCreationRequest.ForeignCountryBusiness
                            ? 'ForeignCountry'
                            : 'Country'
                    ],
                    centerID,
                }
            }
        });
    }
    const callCreateRequestToCenterList = () => {
        createRequestToCentersList({
            variables: {
                data: {
                    subdomain: dataRequestDetails.centerCreationRequest.subdomain,
                    subscriptionPlan: dataRequestDetails.centerCreationRequest.initialSubscriptionPlan.id,
                    isActive: false,
                    owner: dataRequestDetails.centerCreationRequest.owner?.id,
                    creationRequest: dataRequestDetails.centerCreationRequest.id,
                }
            }
        });
    }
    const [updateRequestStatus, { loading: loadingUpdateRequest, data: dataUpdateRequest }] = useMutation(updateRequest, {
        onError: (err) => {
            console.log('error when updating the request status', err);
            setAlertFailure('حدث خطأ ما، يرجى اعادة المحاولة من جديد');
        },
        onCompleted: (data) => {
            console.log('update request status with success');
            setAlertSuccess('تم اجراء العملية بنجاح');
            refetchAll();
        }
    });
    const handleClickApprove = () => {
        approve === true ? setApprove(false) : setApprove(true)
    }
    const handleClickReject = () => {
        console.log('Reject')
        setReject(true);
    }
    const handleRejectRequest = () => {
        console.log('rejectReason', rejectReason);
        updateRequestStatus({ variables: { id: dataRequestDetails.centerCreationRequest.id, status: 'REJECTED', rejectReason: rejectReason } });
        setReject(false);
        handleClose();
        refetchRequests();
    }

    const columns = [
        {
            field: 'projectOwnerName',
            headerName: 'صاحب المشروع',
            flex: 0.5,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'projectName',
            headerName: 'اسم المشروع',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'subdomain',
            headerName: 'اسم النطاق',
            flex: 0.5,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'createdAt',
            headerName: 'تاريخ الطلب',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            type: 'date',
        },
        {
            field: 'status',
            headerName: 'الحالة',
            flex: 0.5,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'notes',
            headerName: 'ملاحظات',
            flex: 0.5,
            headerAlign: 'center',
            align: 'center'
        },
    ];
    // function Transitionfunction(props, ref) {
    //     return <Slide direction="up" ref={ref} {...props} />;
    // }
    // const transition = React.forwardRef(Transitionfunction);
    const row = [];
    const mapper = () => {
        if (!loadingQueryAll)
            dataQueryAll?.centerCreationRequests?.map((item) =>
                row.push({
                    id: item.id,
                    projectOwnerName: item.ProjectOwnerName,
                    projectName: item.ProjectName,
                    subdomain: item.subdomain + '.rwadcenter.com',
                    createdAt: new Date(item.createdAt).toUTCString(),
                    status: Labels[item.status],
                    notes: item?.status === 'ACCEPTED' && !item?.center?.info ? 'خطأ أثناء إنشاء المركز' : '',
                })
            );
        return row;
    };
    const classes = useStyle();
    const handleExport = () => {
        dataToBeExported();
    }
    if (loadingQueryAll || loadingRequestDetails || loadingUpdateRequest || loadingQueryAllForExport)
        return (
            <Backdrop className={classes.loadingStatus} open>
                <CircularProgress color="inherit" />
            </Backdrop>
        );

    return (
        <>
            <Box style={{ width: '100%' }} className={classes.root}>
                <Paper variant="outlined" className={classes.actionsContainer}>
                    <Button onClick={handleExport} variant='contained' color='primary' size='large'>تصدير بيانات الطلبات</Button>
                    <Box className={classes.search} display="flex" justifyContent="space-between">
                        <InputBase
                            placeholder="بحث..."
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                        />
                        <Box className={classes.searchIcon}>
                            <BiSearch />
                        </Box>
                    </Box>
                </Paper>
                <DataGrid
                    className={classes.dataGrid}
                    classes={{
                        columnHeader: classes.head,
                        cell: classes.cell,
                    }}
                    rows={mapper()}
                    columns={columns}
                    pageSize={25}
                    autoHeight
                    rowsPerPageOptions={[0, 1]}
                    onRowClick={(newSelection) => {
                        handleClickRequest(newSelection.id);
                    }}
                />
            </Box>
            <div>
                {/* Dialog Request details Start */}
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                // TransitionComponent={transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
                                معلومات المركز
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={{ marginTop: '60px', padding: '60px 40px' }}>
                        {/* <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}> */}
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650, maxWidth: 750 }}>
                                <TableBody>
                                    {!loadingRequestDetails && dataRequestDetails && Object.entries(dataRequestDetails?.centerCreationRequest).map((item, index) =>
                                        item[0] === 'center' ? null
                                            : <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {Labels[item[0]]}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item[0] === 'initialSubscriptionPlan' ? `${item[1].name} (${item[1].slug})` :
                                                        <>
                                                            {item[0] === 'ProjectPlan' ?
                                                                (
                                                                    item[1]?.[0]?.url
                                                                        ? <Link href={process.env.REACT_APP_API_URL + item[1][0].url}>الخطة</Link>
                                                                        : "(لا توجد)"
                                                                )

                                                                : ['Country', 'ForeignCountry'].includes(item[0]) ?
                                                                    CountryList[item[1]] ?? '(غير محدد)'
                                                                    : <>
                                                                        {
                                                                            item[1]?.username
                                                                            || Labels?.[String(item[1])]
                                                                            || item[1]
                                                                        }

                                                                    </>
                                                            }
                                                        </>
                                                    }

                                                    {/* </pre> */}
                                                </TableCell>
                                            </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {dataRequestDetails &&
                            (dataRequestDetails.centerCreationRequest.status === 'PENDING' ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant='contained' color='primary' size='large' style={{ marginLeft: '10px' }}
                                        onClick={handleClickApprove}
                                    > قبول الطلب</Button>
                                    <Button variant='contained' color='secondary' size='large' onClick={handleClickReject}  >رفض </Button>
                                </div>
                            ) : dataRequestDetails.centerCreationRequest.status === 'REJECTED'
                                || dataRequestDetails.centerCreationRequest.status === 'APPROVED' && dataRequestDetails.centerCreationRequest?.center?.info ? (
                                <div style={{ width: '100%', background: 'green', padding: '20px', color: 'white', textAlign: 'center' }}>
                                    <Typography variant='h6'>تم الرد</Typography>
                                </div>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant='contained' color='primary' size='large' style={{ marginLeft: '10px' }}
                                        onClick={confirmRequest}
                                    >محاولة تصحيح الخطأ</Button>
                                </div>
                            )
                            )}
                        {/* </List> */}
                    </div>
                </Dialog >
                {/* Dialog Request details End */}
                {/* Dialog approve Start */}
                <Dialog open={approve} onClose={handleClickApprove}>
                    <DialogContent>
                        هل أنت متأكد من هذه العملية؟
                        بتأكيدك لهذه العملية، سيتم ارسال رابط الدفع للزبون لاتمام عملية تسجيل المركز
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={confirmRequest}>
                            تأكيد
                        </Button>
                        <Button color="secondary" onClick={handleClickApprove}>إلغاء</Button>
                    </DialogActions>
                </Dialog>
                {/* Dialog approve end */}
                {/* Dialog reject Start */}
                <Dialog
                    fullScreen
                    open={reject}
                    onClose={() => {
                        setReject(false)
                    }}
                // TransitionComponent={transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    setReject(false)
                                }}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
                                ارسال سبب الرفض
                            </Typography>

                        </Toolbar>
                    </AppBar>
                    <div style={{ marginTop: '60px', padding: '60px 40px' }}>
                        <Typography variant='h6'> سبب الرفض </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <TextField
                                variant="outlined"
                                id="outlined-multiline-flexible"
                                label="نص الرفض"
                                multiline
                                fullWidth
                                value={null}
                                onChange={(e) => {
                                    setRejectReason(e.target.value)
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button variant='contained' color='primary' size='large' style={{ marginLeft: '10px' }}
                                onClick={handleRejectRequest}
                            > ارسال</Button>
                            <Button variant='contained' color='secondary' size='large'
                                onClick={() => {
                                    setReject(false)
                                }}  >الغاء </Button>
                        </div>
                    </div>
                </Dialog>
                {/* Dialog reject ends */}
            </div >
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                open={Boolean(alertSuccess)}
                onClose={() => {
                    setAlertSuccess(false);
                }}
                autoHideDuration={3000}
            >
                <MuiAlert
                    // className={classes.muiAlert}
                    elevation={6}
                    variant="filled"
                    severity="success"
                >
                    {alertSuccess}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                open={Boolean(alertFailure)}
                onClose={() => {
                    setAlertFailure(false);
                }}
                autoHideDuration={3000}
            >
                <MuiAlert
                    // className={classes.muiAlert}
                    elevation={6}
                    variant="filled"
                    severity="error"
                >
                    {alertFailure}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default CentersRequests;
