/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, useReducer } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
// Material UI
import { DataGrid } from '@material-ui/data-grid';
import {
  Box,
  makeStyles,
  Paper,
  Button,
  InputBase,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  DialogTitle,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Snackbar,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from "@material-ui/lab/Alert";

import { BiSearch } from 'react-icons/bi';
import TopBar from './TopBar';
import Labels from './RequestLabels'
import CountryList from './CountryList';

// CRUD

const getAllCenters = gql`
  query {
    centersLists {
      id
      subdomain
      isActive
      createdAt
      info {
        name
        country
      }
      owner{username}
      subscriptionPlan {
        slug
        name
      }
    }
  }
`;
const updateCenterStatus = gql`
mutation changeCenterStatus($id:ID!,$status:Boolean!){
  updateCentersList(input:{where:{id:$id}, data:{isActive:$status}}){
    centersList{id}
  }
}
`;
// STYLES

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  dataGrid: {
    cursor: 'pointer',
  },
  loadingStatus: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      cursor: 'pointer',
    },
    marginLeft: theme.spacing(2),
    marginRight: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'relative',
    left: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputRoot: {
    color: 'inherit',
    fontFamily: 'Cairo',
    marginLeft: theme.spacing(2),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  head: {
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
    },
  },

  textField: {
    margin: theme.spacing(2, 0),
  },
  banButton: {
    margin: theme.spacing(2, 0),
  },
  cell: {
    '& .MuiDataGrid-cellRight': {
      textAlign: 'right !important',
    },
  },
  title: {
    float: 'right',
  },
}));

function Centers() {
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailure, setAlertFailure] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState(false);

  // const [open, dispatchOpen] = useReducer((state) => !state, false);

  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  const [alert, dispatchAlert] = useReducer((state) => !state, false);
  const { loading: loadingQueryAll, data: dataQueryAll, refetch: refetchAll } = useQuery(getAllCenters, {
    onError: (err) => console.log('err query centers', err),
    onCompleted: (d) => {
      // console.log('data', d)
    },
  });
  const handleClickClose = () => {
    setOpen(false);
    setSelectedCenter(false);
  }
  const [updateCenterStatusAction, { loading: loadingUpdateCenter, data: dataUpdateCenter }] = useMutation(updateCenterStatus, {
    onError: (err) => {
      console.log('error while updating center status', err);
      refetchAll();
      setAlertFailure('حصل خطأ اثناء التعديل، يرجى اعادة المحاولة');
      handleClickClose();

    },
    onCompleted: (data) => {
      refetchAll();
      setAlertSuccess('تم اتمام العملية بنجاح');
      handleClickClose();
    }
  });
  const handleChangeCenterStatus = () => {
    const status = !selectedCenter.isActive;
    updateCenterStatusAction({ variables: { id: selectedCenter.id, status: !selectedCenter.isActive } });
    dispatchAlert();
  }
  const handleClickCenter = (id) => {
    const centerDetails = dataQueryAll.centersLists?.find(item => item.id === id);
    if (centerDetails) {
      setSelectedCenter(centerDetails);
      setOpen(true);
      console.log('centerDetails', centerDetails);
      console.log('selectedCenter', selectedCenter);
    }
  };

  const columns = [
    { field: 'id', headerName: 'الرقم التعريفي', flex: 0.5, headerAlign: 'center' },
    {
      field: 'owner',
      headerName: 'صاحب المشروع',
      flex: 0.5,
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: 'اسم المشروع',
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'isActive',
      headerName: 'مفعل',
      flex: 0.5,
      headerAlign: 'center',
    },
    {
      field: 'subscriptionPlan',
      headerName: 'الاشتراك',
      flex: 0.5,
      headerAlign: 'center',
    },
  ];
  const row = [];
  const mapper = () => {
    if (!loadingQueryAll)
      dataQueryAll.centersLists?.map((item) =>
        row.push({
          id: item.id,
          owner: item.owner?.username || '(غير محدد)',
          name: item.info?.name,
          subscriptionPlan: item.subscriptionPlan ? `${item.subscriptionPlan?.name} (${item.subscriptionPlan?.slug})` : '(غير محدد)',
          isActive: Labels[item.isActive],
        })
      );
    return row;
  };
  const classes = useStyle();

  if (loadingQueryAll || loadingUpdateCenter)
    return (
      <Backdrop className={classes.loadingStatus} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  if (!loadingQueryAll)
    return (
      <Box style={{ width: '100%' }} className={classes.root}>
        {/* Dialog Delete Start */}
        <Dialog open={alert} onClose={dispatchAlert}>
          <DialogContent>هل أنت متأكد من هذه العملية؟</DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleChangeCenterStatus}>تأكيد</Button>
            <Button color="secondary" onClick={dispatchAlert}>إلغاء</Button>
          </DialogActions>
        </Dialog>

        {/* Dialog Center details Start */}
        <Dialog
          fullScreen
          open={selectedCenter}
          onClose={handleClickClose}
        // TransitionComponent={transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClickClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
                معلومات المركز
              </Typography>
            </Toolbar>
          </AppBar>
          <div style={{ marginTop: '60px', padding: '60px 40px' }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650, maxWidth: 750 }} aria-label="simple table">
                <TableBody>
                  {selectedCenter && Object.entries(selectedCenter).map((item, index) =>
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {Labels[item[0]]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {console.log('item', item)}
                        {
                          Labels?.[String(item[1])]
                          || item[1]?.username
                          || ((item[0] === 'subscriptionPlan') && `${item[1].name} (${item[1].slug})`)
                          || ((item[0] === 'country') && CountryList[item[1]])
                          || item[1]?.name
                          || item[1]
                        }
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {(selectedCenter.isActive === true) ? (
                <Button variant='contained' color='secondary' size='large' style={{ width: '25%', height: '50px' }}
                  onClick={dispatchAlert} >حظر</Button>
              ) : ''}
              {(selectedCenter.isActive === false) ? (
                <Button variant='contained' color='primary' size='large' style={{ marginLeft: '10px', width: '25%', height: '50px' }}
                  onClick={dispatchAlert} > تفعيل</Button>) : ''}

            </div>
          </div>
        </Dialog>
        {/* Dialog Request details End */}
        <Paper variant="outlined" className={classes.actionsContainer}>

          <Box className={classes.search} display="flex" justifyContent="space-between">
            <InputBase
              placeholder="بحث..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
            <Box className={classes.searchIcon}>
              <BiSearch />
            </Box>
          </Box>
        </Paper>
        <DataGrid
          className={classes.dataGrid}
          classes={{
            columnHeader: classes.head,
            cell: classes.cell,
          }}
          rows={mapper()}
          columns={columns}
          pageSize={25}
          autoHeight
          rowsPerPageOptions={[0, 1]}
          onRowClick={(newSelection) => {
            handleClickCenter(newSelection.id);
          }}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={Boolean(alertSuccess)}
          onClose={() => {
            setAlertSuccess(false);
          }}
          autoHideDuration={3000}
        >
          <MuiAlert
            // className={classes.muiAlert}
            elevation={6}
            variant="filled"
            severity="success"
          >
            {alertSuccess}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={Boolean(alertFailure)}
          onClose={() => {
            setAlertFailure(false);
          }}
          autoHideDuration={3000}
        >
          <MuiAlert
            // className={classes.muiAlert}
            elevation={6}
            variant="filled"
            severity="error"
          >
            {alertFailure}
          </MuiAlert>
        </Snackbar>
      </Box >

    );
}

export default Centers;
