/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { onError } from 'apollo-link-error';
import { setContext } from '@apollo/client/link/context';
// Material-UI
import {
  ThemeProvider,
  CssBaseline,
  jssPreset,
  StylesProvider,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
// RTL
import rtl from 'jss-rtl';
import { create } from 'jss';
import Interface from './Components/Content/Interface';
// Components
import Login from './Components/Login';

// eslint-disable-next-line no-unused-vars
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL + '/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const jwt = sessionStorage.getItem('admins-jwt');
  const token = jwt ? `Bearer ${jwt}` : '';

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

// Styles
// const useStyle = makeStyles(() => ({
//   navigation: {
//     width: '100%',
//   },
// }));
// RTL
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const rtlTheme = createTheme({ direction: 'rtl' });
const isAuth = sessionStorage.getItem('admins-jwt');

function App() {
  return (
    <ApolloProvider client={client}>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={rtlTheme}>
          <CssBaseline />
          <div id="home">
            {isAuth ? (
              <Interface />
            ) : (
              <div id="login">
                <Login />
              </div>
            )}
          </div>
        </ThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  );
}

export default App;
