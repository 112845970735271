/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, useReducer } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

// Material UI
import { DataGrid } from '@material-ui/data-grid';
import {
  Box,
  makeStyles,
  Paper,
  Checkbox,
  Button,
  InputBase,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  DialogTitle,
  Typography,
  Divider,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles'
import { BiSearch, BiWindows } from 'react-icons/bi';

// CRUD

const queryAllOpen = gql`
  query {
    techSupports(sort: "updatedAt:desc") {
      user
      id
      isOpen
      updatedAt
    }
  }
`;
const queryAllClosed = gql`
  query {
    techSupports(where: { isOpen: false }) {
      user
      id
    }
  }
`;
const queryTicket = gql`
  query TechSupport($id: ID!) {
    techSupport(id: $id) {
      user
      isOpen
      ticket {
        date
        content
        isTechSupport
      }
    }
  }
`;
const mutationReply = gql`
  mutation UpdateTechSupport(
    $id: ID!
    $user: String!
    $isOpen: Boolean!
    $content: [editComponentTechSupportTicketInput]!
  ) {
    updateTechSupport(
      input: {
        where: { id: $id }
        data: { user: $user, isOpen: $isOpen, ticket: $content }
      }
    ) {
      techSupport {
        ticket {
          date
          content
          isTechSupport
        }
      }
    }
  }
`;

// STYLES

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  dataGrid: {
    cursor: 'pointer',
  },
  loadingStatus: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      cursor: 'pointer',
    },
    marginLeft: theme.spacing(2),
    marginRight: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'relative',
    left: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputRoot: {
    color: 'inherit',
    fontFamily: 'Cairo',
    marginLeft: theme.spacing(2),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  head: {
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
    },
  },
  cairo: {
    fontFamily: 'Cairo',
  },

  textField: {
    width: '60%',
  },
  banButton: {
    margin: theme.spacing(2, 0),
  },
  cell: {
    '& .MuiDataGrid-cellRight': {
      textAlign: 'right !important',
    },
  },
  userText: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
  isOpenText: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
  title: {
    float: 'right',
  },
  ticketDisplay: {},

  ticketContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    width: '60%',
  },
  ticketTop: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
  },
  ticketTopSupport: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  ticketSupport: { fontFamily: 'Cairo' },
  ticketUser: { fontFamily: 'Cairo', color: theme.palette.primary.main },
  ticketContent: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    fontFamily: 'Cairo',
  },
  ticketDate: { fontFamily: 'Cairo', color: theme.palette.grey[500] },
  openDivider: { marginTop: theme.spacing(3) },
}));

function Support() {
  const [query, setQuery] = useState(true);
  const [selection, setSelection] = useState(false);
  const [user, setUser] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState('');
  const [open, dispatchOpen] = useReducer((state) => !state, false);
  const [action, dispatchAction] = useReducer((state) => !state, false);
  const [checked, dispatchChecked] = useReducer((state) => !state, false);

  const { loading: loadingQueryAllOpen, data: dataQueryAllOpen } = useQuery(
    queryAllOpen,
    {
      onError: (error) => {
        console.log('errorQueryAll: ', error);
      },
    },
  );
  const { loading: loadingQueryTicket, data: dataQueryTicket } = useQuery(
    queryTicket,
    {
      skip: query,
      variables: { id: selection },
      onCompleted: (data) => {
        setUser(data.techSupport.user);
        setIsOpen(data.techSupport.isOpen);
      },
      onError: (error) => {
        console.log('errorQueryTicket:', error);
      },
    },
  );
  const [replyTicket] = useMutation(mutationReply, {
    onCompleted: (data) => {
      console.log('dataMutationReplyTicket: ', data);
    },
    onError: (error) => {
      console.log('errorMutationReplyTicket: ', error);
    },
  });

  useEffect(() => {
    console.log('selection', selection);
    console.log('query', query);
  }, [selection, query]);

  const columns = [
    { field: 'id', headerName: 'الرقم', flex: 0.5, headerAlign: 'center' },
    {
      field: 'user',
      headerName: 'اسم المستخدم',
      flex: 0.5,
      headerAlign: 'center',
    },
    {
      field: 'isOpen',
      headerName: 'الحالة',
      flex: 0.5,
      headerAlign: 'center',
    },
  ];
  const row = [];
  const mapper = () => {
    try {
      dataQueryAllOpen.techSupports.map((item) =>
        row.push({
          id: item.id,
          user: item.user,
          isOpen: item.isOpen ? 'مفتوحة' : 'مغلقة',
        }),
      );
    } catch (error) {
      console.log('error mapping data: ', error);
    }
    return row;
  };
  async function sendReply() {
    const data = [...dataQueryTicket.techSupport.ticket];
    data.push({
      date: '2021-06-16T14:42:33.701Z',
      content,
      isTechSupport: true,
    });
    await replyTicket({
      variables: {
        id: selection,
        isOpen: !checked,
        user,
        content: data,
      },
    })
      .then(() => {
        dispatchAction();
        setQuery(true);
        setSelection(false);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log('replyTicket err', err);
      });
  }

  const TicketMapper = () => {
    const classes = useStyle();
    return dataQueryTicket.techSupport.ticket.map((i, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Paper className={classes.ticketContainer} key={index}>
        {i.isTechSupport ? (
          <Paper
            className={classes.ticketTopSupport}
            key={() => {
              Math.random();
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className={classes.ticketSupport}>
                الدعم الفني
              </Typography>
              <Typography className={classes.ticketDate}>{i.date}</Typography>
            </Box>
          </Paper>
        ) : (
          <Paper className={classes.ticketTop}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className={classes.ticketUser}>{user} </Typography>
              <Typography className={classes.ticketDate}>{i.date}</Typography>
            </Box>
          </Paper>
        )}

        <Typography className={classes.ticketContent}>{i.content}</Typography>
      </Paper>
    ));
  };
  const classes = useStyle();

  if (loadingQueryAllOpen || loadingQueryTicket)
    return (
      <Backdrop className={classes.loadingStatus} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  return (
    <Box style={{ width: '100%' }} className={classes.root}>
      {/* Dialog Read Start */}
      <Dialog open={open} fullWidth>
        <DialogTitle id="form-dialog-title">تذكرة الدعم الفني</DialogTitle>

        <DialogContent>
          <Box display="flex" justifyContent="space-evenly">
            <Typography className={classes.cairo}>
              المستخدم:<span className={classes.userText}>{user}</span>
            </Typography>
            <Typography className={classes.cairo}>
              الحالة:
              <span className={classes.isOpenText}>
                {isOpen ? 'مفتوحة' : 'مغلقة'}
              </span>
            </Typography>
          </Box>

          {query ? '' : <TicketMapper />}
          <Divider className={classes.openDivider} />
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            margin="dense"
            label="كتابة رد"
            type="content"
            onChange={(e) => setContent(e.target.value)}
            multiline
            rows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              sendReply();
            }}
          >
            رد
          </Button>
          <Button color="secondary" onClick={dispatchAction}>
            إغلاق
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog Read End */}

      <Paper variant="outlined" className={classes.actionsContainer}>
        <Box
          className={classes.search}
          display="flex"
          justifyContent="space-between"
        >
          <InputBase
            placeholder="بحث..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
          <Box className={classes.searchIcon}>
            <BiSearch />
          </Box>
        </Box>
      </Paper>
      {action ? (
        <Box
          className={classes.ticketDisplay}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <TicketMapper />
          <Divider className={classes.openDivider} />
          <Box className={classes.textField}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              label="كتابة رد"
              type="content"
              onChange={(e) => setContent(e.target.value)}
              multiline
              rows={3}
            />

            <Button
              color="primary"
              onClick={() => {
                sendReply();
              }}
            >
              رد
            </Button>

            <Button color="secondary" onClick={dispatchAction}>
              إغلاق
            </Button>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox checked={checked} onChange={dispatchChecked} />
            <Typography className={classes.cairo}> غلق التذكرة</Typography>
          </Box>
        </Box>
      ) : (
        <DataGrid
          className={classes.dataGrid}
          classes={{
            columnHeader: classes.head,
            cell: classes.cell,
          }}
          rows={mapper()}
          columns={columns}
          pageSize={25}
          autoHeight
          hideFooterRowCount
          hideFooterSelectedRowCount
          rowsPerPageOptions={[0, 1]}
          onRowClick={(newSelection) => {
            setSelection(newSelection.id);
            setQuery(false);
            setUser(newSelection.user);
            // dispatchAction();
            dispatchAction();
          }}
        />
      )}
    </Box>
  );
}

export default Support;
