/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
// Material UI
import {
  Box,
  makeStyles,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles'

import SendIcon from '@material-ui/icons/Send';

// CRUD


// STYLES

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  dataGrid: {
    cursor: 'pointer',
  },
  loadingStatus: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  actionsContainer: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      cursor: 'pointer',
    },
    marginLeft: theme.spacing(2),
    marginRight: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'relative',
    left: 0,
    pointerEvents: 'none',
  },
  inputRoot: {
    color: 'inherit',
    fontFamily: 'Cairo',
    marginLeft: theme.spacing(2),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  head: {
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
    },
  },

  textField: {
    margin: theme.spacing(2, 0),
  },
  banButton: {
    margin: theme.spacing(2, 0),
  },
  cell: {
    '& .MuiDataGrid-cellRight': {
      textAlign: 'right !important',
    },
  },
  title: {
    float: 'right',
  },
  Typography: {
    fontFamily: 'Cairo',
  }
}));

function Dashboard() {
  const classes = useStyle();
  return (
    <Box style={{ width: '100%' }} className={classes.root}>
      <Paper variant="outlined" className={classes.actionsContainer}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant='h5' className={classes.Typography}>مرحبا بك في ادارة الرواد</Typography>
          </Box>
          <Box>
            <Typography variant='h6' className={classes.Typography}>اختر من القائمة الصفحة التي تريد الانتقال إليها</Typography>
          </Box>
        </Box>
        <Box style={{ marginTop: 100 }}>
          <Typography variant='h4' className={classes.Typography}>روابط هامة</Typography>
          <List>
          <Divider />
            <ListItem button onClick={()=>{
                window.open('https://rwadcenter.com', '_blank');
              }}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="h6" className={classes.inputRoot}>الموقع الرئيسي</Typography>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem button onClick={()=>{
                window.open('https://backend.rwadcenter.com/admin', '_blank');
              }}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="h6" className={classes.inputRoot}>واجهة Strapi</Typography>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem button onClick={()=>{
                window.open('https://academy.rwadcenter.com', '_blank');
              }}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="h6" className={classes.inputRoot}>الأكاديمية</Typography>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem button onClick={()=>{
                window.open('https://studio.academy.rwadcenter.com', '_blank');
              }}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText>
                  <Typography variant="h6" className={classes.inputRoot}>الاستوديو</Typography>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem button onClick={()=>{
                window.open('https://studio.academy.rwadcenter.com/admin', '_blank');
              }}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="h6" className={classes.inputRoot}>إدارة الأكاديمية</Typography>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem button onClick={()=>{
                window.open('https://ecommerce.rwadcenter.com/dashboard/', '_blank');
              }}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="h6" className={classes.inputRoot}>المتجر الالكتروني</Typography>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem button onClick={()=>{
                window.open('https://ecommerce.rwadcenter.com/admin', '_blank');
              }}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="h6" className={classes.inputRoot}>إدارة المتجر الالكتروني</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Paper>

    </Box >

  );
}

export default Dashboard;
