
const Labels = {
id:'رقم التعريف',
ProjectName: 'اسم المشروع',
ProjectOwnerName:'اسم المسؤول عن المشروع',
owner:'مدير المركز',
Address:'العنوان',
Phone:'الهاتف',
Town:'المدينة',
Country:'البلد',
Email:'البريد الالكتروني',
Website:'الموقع الالكتروني',
IsProjectReady:'هل مشروعكم قائم؟ أم تريدون البدء لأول مرة؟',
ProjectPlan:'الخطة',
ProjectFeatures:'اذكر اهم المميزات التي توجد في مشروعكم',
ProjectDisadvantages:'اذكر اهم العيوب التي توجد في مشروعكم',
ProjectBarriers:'اذكر اهم العوائق التي يواجهها مشروعكم',
FundingSource:'اذا كان اجابتكم خيري او شبه خيري فمن اين تحصلون على التمويل؟',
IsLegalState:'هل وضع مشروعكم قانوني ؟(سجلتم مشروعكم في الحكومة)',
WhyIsProjectNotLegal:'اذا كان جوابكم لا، هل لأن مشروعكم ليست قائمة؟ فبين السبب',
HaveManagementExperience:'هل عنكم خبرة في ادارة المشاريع؟',
WhyNotHavingManagementExperience:'اذا كان جوابكم لأ، هل لأن مشروعكم ليست قائمة؟ فبين السبب',
TargetCustomers:'من هم جمهوركم المستهدف؟',
TargetAges:'اعمارهم',
TargetGender:'جنسهم',
TargetCertifications:'شهاداتهم',
TargetSalaries:'دخلهم',
ForeignCountryBusiness:'هل تريدون تطبيق مشروعكم القيادي داخل بلدكم، ام خارجها؟',
ForeignCountry:'اذا كان خارج بلدكم فاكتب البلد الذي تطبقون فيها المشروع',
ExpectationsFromRwad:'ماذا تتوقعون من الرواد ان يقدم لكم و ماذا تحتاجون؟',
HavingSpecialCurriculumInLeadershipTraining:'هل لديكم منهج خاص في مجال التدريب القيادي؟',
SpecialCurriculumInLeadershipTraining:'اذا كان لديكم منهج، فما هي؟ وما اسم المؤسسة او الجهة المؤلفة؟',
domainOfWork:'',
subdomain:'النطاق الفرعي',
status:'حالة الطلب',
rejectReason:'سبب الرفض',
subscriptionPlan:'نوع الاشتراك',
ProjectType:'نوع المشروع',
createdAt: 'تم انشاءه بتاريخ',
PENDING: 'قيد المراجعة',
APPROVED: 'تم قبوله',
REJECTED: 'تم رفضه',
isActive: 'مفعل',
name: 'اسم المركز',
info: 'اسم المركز',
initialSubscriptionPlan:'نوع الاشتراك',
'': '(غير محدد)',
null: '(غير محدد)',
false: 'لا',
true: 'نعم',
}

export default Labels;