/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, useReducer } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

// Material UI
import { DataGrid } from '@material-ui/data-grid';
import {
  Box,
  makeStyles,
  Paper,
  Button,
  InputBase,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  DialogTitle,
  Typography,
  Divider,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles'
import { BiSearch } from 'react-icons/bi';

// CRUD

const queryAll = gql`
  query {
    contactuses {
      id
      fullName
      email
      createdAt
    }
  }
`;
const queryLetter = gql`
  query QueryLetter($id: ID!) {
    contactUs(id: $id) {
      id
      fullName
      email
      content
      createdAt
    }
  }
`;

const mutationDelete = gql`
  mutation DeleteContactUs($id: ID!) {
    deleteContactUs(input: { where: { id: $id } }) {
      contactUs {
        fullName
        email
      }
    }
  }
`;

// STYLES

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  dataGrid: {
    cursor: 'pointer',
  },
  loadingStatus: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      cursor: 'pointer',
    },
    marginLeft: theme.spacing(2),
    marginRight: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'relative',
    left: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputRoot: {
    color: 'inherit',
    fontFamily: 'Cairo',
    marginLeft: theme.spacing(2),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  messageDisplay: {
    width: '70%',
  },
  head: {
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
    },
  },
  cairo: {
    fontFamily: 'Cairo',
  },
  fullNameText: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
  emailText: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },

  textField: {
    margin: theme.spacing(2, 0),
  },
  banButton: {
    margin: theme.spacing(2, 0),
  },
  cell: {
    '& .MuiDataGrid-cellRight': {
      textAlign: 'right !important',
    },
  },
  title: {
    float: 'right',
  },
}));

function Contact() {
  const [query, setQuery] = useState(true);
  const [selection, setSelection] = useState(false);
  const [contactId, setContactId] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [initialContent, setInitialContent] = useState('');
  const [alert, dispatchAlert] = useReducer((state) => !state, false);
  const [success, dispatchSuccess] = useReducer((state) => !state, false);
  const [failure, dispatchFailure] = useReducer((state) => !state, false);
  const [reply, dispatchReply] = useReducer((state) => !state, false);
  const [action, dispatchAction] = useReducer((state) => !state, false);

  const { loading: loadingQueryAll, data: dataQueryAll, refetch: refetchAll } = useQuery(queryAll, {
    onError: (error) => {
      console.log('errorQueryAll: ', error);
    },
  });
  const { loading: loadingQueryLetter } = useQuery(queryLetter, {
    skip: query,
    variables: { id: selection },
    onCompleted: (data) => {
      console.log({data})
      setContactId(data.contactUs.id)
      setFullName(data.contactUs.fullName);
      setEmail(data.contactUs.email);
      setCreatedAt(new Date(data.contactUs.createdAt).toLocaleDateString('ar-EG-u-nu-latn',{weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'}))
      setContent(data.contactUs.content);
      setInitialContent(data.contactUs.content);
      dispatchAction();
      setQuery(true);
      setSelection(false);
    },
    onError: (error) => {
      console.log('errorQueryLetter:', error);
    },
  });
  const [deleteLetter, {loading: loadingDelete}] = useMutation(mutationDelete, {
    onCompleted: () => {
      dispatchAction();
      dispatchAlert();
      refetchAll();
      console.log('Message deleted successfully');
    },
    onError: (error) => {
      dispatchAction();
      dispatchAlert();
      refetchAll();
      console.log('errorMutationDeleteLetter: ', error);
    },
  });

  useEffect(() => {
    console.log('email', email);
    console.log('subject', title);
    console.log('content', content);
  }, [email, title, content]);
  const sendReply = () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization':
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwYzlmY2QyNmUwZTVjZGUwYTk1ZDJjMyIsImlhdCI6MTYyNDM2OTA1MCwiZXhwIjoxNjI2OTYxMDUwfQ.qU6skR_q_c6MJjlId4kUK5UOU6YDJI2hIAtt0od9P68',
      },
      body: JSON.stringify({
        to: email,
        subject: title,
        reply: content,
      }),
    };
    fetch('https://admin.rwadcenter.com/contact-uses/send-mail-reply', requestOptions)
      .then(() => {
        dispatchSuccess();
        dispatchReply();
        dispatchAction();

        setEmail('');
        setTitle('');
        setContent('');
        setInitialContent('');
      })
      .catch((err) => {
        console.log(err);
        dispatchFailure();
      });
  };
  const columns = [
    { field: 'id', headerName: 'الرقم', flex: 0.5, headerAlign: 'center' },
    {
      field: 'fullName',
      headerName: 'الاسم الكامل',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'email',
      headerName: 'البريد الإلكتروني',
      flex: 1,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'createdAt',
      headerName: 'تاريخ الارسال',
      flex: 1,
      headerAlign: 'center',
      align: 'center'
    },
  ];
  const row = [];
  const mapper = () => {
    try {
      dataQueryAll.contactuses.map((item) =>
        row.push({
          id: item.id,
          fullName: item.fullName,
          email: item.email,
          createdAt: new Date(item.createdAt).toLocaleDateString('ar-EG-u-nu-latn',{weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'})
        }),
      );
    } catch (error) {
      console.log('error mapping data: ', error);
    }
    return row;
  };
  const classes = useStyle();

  if (loadingQueryAll || loadingQueryLetter)
    return (
      <Backdrop className={classes.loadingStatus} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  return (
    <Box style={{ width: '100%' }} className={classes.root}>
      {/* Dialog Delete Start */}
      <Dialog open={alert} onClose={dispatchAlert}>
        <DialogContent>هل أنت متأكد من هذه العملية؟</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              deleteLetter({
                variables: {
                  id: contactId,
                },
            });
            }}
            disabled={loadingDelete}
          >
            تأكيد
          </Button>
          <Button color="secondary" onClick={dispatchAlert}>إلغاء</Button>
        </DialogActions>
      </Dialog>
      {/* Dialog Delete End */}

      <Paper variant="outlined" className={classes.actionsContainer}>
        <Box
          className={classes.search}
          display="flex"
          justifyContent="space-between"
        >
          <InputBase
            placeholder="بحث..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
          <Box className={classes.searchIcon}>
            <BiSearch />
          </Box>
        </Box>
      </Paper>
      {action ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box className={classes.messageDisplay}>
            <Button
              color="secondary"
              className={classes.title}
              onClick={dispatchAlert}
            >
              حذف
            </Button>

            <Box display="flex" justifyContent="space-evenly">
              <Typography className={classes.cairo}>
                الإسم الكامل:
                <span className={classes.fullNameText}>{fullName}</span>
              </Typography>
              <Typography className={classes.cairo}>
                البريد الإلكتروني:
                <span className={classes.emailText}>{email}</span>
              </Typography>
              <Typography className={classes.cairo}>
                تاريخ الارسال:
                <span className={classes.emailText}>{createdAt}</span>
              </Typography>
            </Box>

            <TextField
              className={classes.textField}
              fullWidth
              variant="outlined"
              margin="dense"
              label="المحتوِى"
              type="content"
              value={initialContent}
              multiline
              rows={8}
              InputProps={{
                readOnly: true,
              }}
            />

           
            <Button variant='outlined' color="primary" onClick={dispatchAction}>
              عودة إلى الرسائل
            </Button>
          </Box>
        </Box>
      ) : (
        <DataGrid
          className={classes.dataGrid}
          classes={{
            columnHeader: classes.head,
            cell: classes.cell,
          }}
          rows={mapper()}
          columns={columns}
          pageSize={25}
          autoHeight
          hideFooterRowCount
          hideFooterSelectedRowCount
          rowsPerPageOptions={[0, 1]}
          onRowClick={(newSelection) => {
            setSelection(newSelection.id);
            setQuery(false);
          }}
        />
      )}
    </Box>
  );
}

export default Contact;
