/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { gql, useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',

    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    height: 'fit-content',
    width: 'fit-content',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  copyright: {
    fontFamily: 'Cairo',
  },
  c: {
    fontFamily: 'Arial',
    fontSize: theme.spacing(2),
    margin: theme.spacing(0, 0.4),
  },
  error:{
    color: 'red',
  },
}));
const mutationLogin = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password }) {
      jwt
      user {
        id
        username
        email
        role{
        type
      }
      }
    }
  }
`;
function Copyright() {
  const classes = useStyles();

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.copyright}
    >
      جميع الحقوق محفوظة <span className={classes.c}>©</span>
      <Link color="inherit" href="https://material-ui.com/">
        الرواد
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null)
  const [requestLogin] = useMutation(mutationLogin, {
    onCompleted: (data) => {
      if (data.login.user.role.type === 'administration') {
        console.log('dataMutationLogin: ', data);
        sessionStorage.setItem('admins-jwt', data.login.jwt);
        sessionStorage.setItem('admin', [data.login.user.username]);
        window.location.reload(false);
      }
      else {
        console.log(data)
        setError('حسابك لا يمتلك صلاحية الدخول إلى منصة الإدارة. حاول بحساب آخر')
        // window.location.reload(false);
      }

    },
    onError: (error) => {
      if(error.networkError){
        setError('خلل في الاتصال، يرجى التأكد من اتصالك بالانترنت و إعادة المحاولة ')
      }
      else if(error.clientErrors.length){
        setError('clientError')
      }
      else if(error.graphQLErrors.length){
        setError('بيانات الدخول خاطئة، تحقق من بريدك الإلكتروني و كلمة المرور ثم أعد المحاولة.')
      }
      console.log(JSON.stringify(error, null, 2))
    },
  });

  return (
    <Box className={classes.root}>
      <Container className={classes.paper} component="main" maxWidth="xs">
        <CssBaseline />
        <img src="./assets/logoBig.png" alt="logo" />
        <Typography component="h1" variant="h5" className={classes.copyright}>
          دخول الإدارة
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="البريد الإلكتروني"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="كلمة السر"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => {
              e.preventDefault();
              requestLogin({
                variables: {
                  email,
                  password,
                },
              });
            }}
          >
            دخول
          </Button>
          <Typography className={classes.error}>
            {
              error
            }
          </Typography>
        </form>
          
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Box>
  );
}
export default Login;
