/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, useReducer } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
// Material UI
import { DataGrid } from '@material-ui/data-grid';
import {
  Box,
  makeStyles,
  Paper,
  Button,
  InputBase,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles'
import { BiSearch } from 'react-icons/bi';
import TopBar from './TopBar';

// CRUD

const queryAll = gql`
  query {
    users {
      id
      username
      email
      confirmed
      blocked
    }
  }
`;
const queryUser = gql`
  query QueryUser($id: ID!) {
    user(id: $id) {
      username
      email
      blocked
      confirmed
    }
  }
`;
const mutationAdd = gql`
  mutation CreateUser(
    $username: String!
    $password: String!
    $email: String!
    $confirmed: Boolean
    $blocked: Boolean!
  ) {
    createUser(
      input: {
        data: {
          username: $username
          email: $email
          password: $password
          confirmed: $confirmed
          blocked: $blocked
        }
      }
    ) {
      user {
        username
        email
      }
    }
  }
`;
const mutationUpdate = gql`
  mutation UpdateUser(
    $id: ID!
    $username: String!
    $email: String!
    $password: String
    $confirmed: Boolean!
    $blocked: Boolean!
  ) {
    updateUser(
      input: {
        where: { id: $id }
        data: {
          username: $username
          email: $email
          password: $password
          confirmed: $confirmed
          blocked: $blocked
        }
      }
    ) {
      user {
        username
        email
      }
    }
  }
`;
const mutationDelete = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(input: { where: { id: $id } }) {
      user {
        username
        email
      }
    }
  }
`;

// STYLES

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  dataGrid: {
    cursor: 'pointer',
  },
  loadingStatus: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
      cursor: 'pointer',
    },
    marginLeft: theme.spacing(2),
    marginRight: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'relative',
    left: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputRoot: {
    color: 'inherit',
    fontFamily: 'Cairo',
    marginLeft: theme.spacing(2),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  head: {
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
    },
  },

  textField: {
    margin: theme.spacing(2, 0),
  },
  banButton: {
    margin: theme.spacing(2, 0),
  },
  cell: {
    '& .MuiDataGrid-cellRight': {
      textAlign: 'right !important',
    },
  },
  title: {
    float: 'right',
  },
}));

function Users() {
  const [query, setQuery] = useState(true);
  const [selection, setSelection] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [blocked, setBlocked] = useState(false);

  const [open, dispatchOpen] = useReducer((state) => !state, false);
  const [update, setUpdate] = useState(false);

  const [alert, dispatchAlert] = useReducer((state) => !state, false);
  const { loading: loadingQueryAll, data: dataQueryAll } = useQuery(queryAll);
  const { loading: loadingQueryUser } = useQuery(queryUser, {
    skip: query,
    variables: { id: selection },
    onCompleted: (data) => {
      setUsername(data.user.username);
      setEmail(data.user.email);
      setConfirmed(data.user.confirmed);
      setBlocked(data.user.blocked);
      dispatchOpen();
      console.log('successQueryUser:', data);
      setQuery(true);
    },
    onError: (error) => {
      console.log('errorQueryUser:', error);
    },
  });
  const [addUser] = useMutation(mutationAdd, {
    onCompleted: (data) => {
      setUsername('');
      setPassword(false);
      setEmail('');
      setBlocked(false);
      setConfirmed(false);
      window.location.reload(false);

      console.log('dataMutationAddUser: ', data);
    },
    onError: (error) => {
      console.log('errorMutationAddUser:', error);
    },
  });
  const [updateUser] = useMutation(mutationUpdate, {
    onCompleted: (data) => {
      window.location.reload();

      console.log('dataMutationUpdateUser: ', data);
    },
    onError: (error) => {
      console.log('errorMutationUpdateUser: ', error);
    },
  });
  const [deleteUser] = useMutation(mutationDelete, {
    onCompleted: (data) => {
      dispatchOpen();
      dispatchAlert();
      window.location.reload(false);

      console.log('dataMutationDeleteUser: ', data);
    },
    onError: (error) => {
      console.log('errorMutationDeleteUser: ', error);
    },
  });

  useEffect(() => {
    console.log('selection', selection);
    console.log('update', update);
    console.log('query', query);
  }, [selection, query, update]);

  const columns = [
    { field: 'id', headerName: 'الرقم', flex: 0.5, headerAlign: 'center' },
    {
      field: 'username',
      headerName: 'اسم المستخدم',
      flex: 0.5,
      headerAlign: 'center',
    },
    {
      field: 'email',
      headerName: 'البريد الإلكتروني',
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'isConfirmed',
      headerName: 'التفعيل',
      flex: 0.5,
      headerAlign: 'center',
    },
    {
      field: 'isBlocked',
      headerName: 'الحالة',
      flex: 0.5,
      headerAlign: 'center',
    },
  ];
  const row = [];
  const mapper = () => {
    dataQueryAll?.users?.map((item) =>
      row.push({
        id: item.id,
        username: item.username,
        email: item.email,
        isBlocked: item.blocked ? 'محظور' : '',
        isConfirmed: item.confirmed ? 'مفعل' : 'غير مفعل',
      }),
    );
    return row;
  };
  const classes = useStyle();

  const Ban = () => {
    if (blocked) {
      return (
        <Button
          className={classes.banButton}
          color="primary"
          fullWidth
          variant="contained"
          onClick={() => {
            setBlocked(false);
          }}
        >
          إلغاء الحظر
        </Button>
      );
    }
    return (
      <Button
        className={classes.banButton}
        color="secondary"
        fullWidth
        variant="contained"
        onClick={() => {
          setBlocked(true);
        }}
      >
        حظر
      </Button>
    );
  };
  const handleActivation = () => {
    setConfirmed(true);
  };
  const Activation = () => {
    if (!confirmed)
      return (
        <Button
          className={classes.banButton}
          color="primary"
          fullWidth
          variant="contained"
          onClick={handleActivation}
        >
          تفعيل
        </Button>
      );
    return (
      <Button
        className={classes.banButton}
        color="primary"
        fullWidth
        disabled
        variant="contained"
      >
        مفعل
      </Button>
    );
  };
  const determineButton = () => {
    if (selection === false) return 'إضافة';
    return 'تحديث';
  };
  if (loadingQueryAll || loadingQueryUser)
    return (
      <Backdrop className={classes.loadingStatus} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  return (
    <Box style={{ width: '100%' }} className={classes.root}>
      {/* Dialog Delete Start */}
      <Dialog open={alert} onClose={dispatchAlert}>
        <DialogContent>هل أنت متأكد من هذه العملية؟</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              deleteUser({
                variables: {
                  id: selection,
                },
              });
            }}
          >
            تأكيد
          </Button>
          <Button color="secondary">إلغاء</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Update Start */}
      <Dialog
        open={open}
        onClose={() => {
          dispatchOpen();
          setUpdate(false);
          setSelection(false);
        }}
      >
        <DialogTitle id="form-dialog-title">
          تعديل معلومات المستخدم
          <Button
            color="secondary"
            className={classes.title}
            onClick={dispatchAlert}
          >
            حذف
          </Button>
        </DialogTitle>

        <DialogContent>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            margin="dense"
            label="اسم المستخدم"
            type="username"
            defaultValue={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            margin="dense"
            label="البريد الإلكتروني"
            type="email"
            defaultValue={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            margin="dense"
            label="كلمة السر"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Box display="flex" justifyContent="space-around">
            <Activation />
            <Box mx={2}> </Box>
            <Ban />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              if (update === false) {
                addUser({
                  variables: {
                    username,
                    email,
                    password,
                    blocked,
                    confirmed,
                  },
                });
              } else if (!password) {
                updateUser({
                  variables: {
                    id: selection,
                    username,
                    email,
                    blocked,
                    confirmed,
                  },
                });
                console.log('update without password');
              } else {
                updateUser({
                  variables: {
                    id: selection,
                    username,
                    email,
                    password,
                    blocked,
                    confirmed,
                  },
                });
                console.log('update with password');
              }
              dispatchOpen();
            }}
          >
            {determineButton()}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              dispatchOpen();
              setUsername('');
              setPassword(false);
              setEmail('');
              setBlocked(false);
              setConfirmed(false);
              setSelection(false);
            }}
          >
            إلغاء
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog End */}
      <Paper variant="outlined" className={classes.actionsContainer}>
        <Button
          variant="outlined"
          onClick={() => {
            setUsername('');
            setPassword(false);
            setEmail('');
            setBlocked(false);
            setConfirmed(false);
            dispatchOpen();
          }}
          color="primary"
        >
          إضافة
        </Button>

        <Box
          className={classes.search}
          display="flex"
          justifyContent="space-between"
        >
          <InputBase
            placeholder="بحث..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
          <Box className={classes.searchIcon}>
            <BiSearch />
          </Box>
        </Box>
      </Paper>
      <DataGrid
        className={classes.dataGrid}
        classes={{
          columnHeader: classes.head,
          cell: classes.cell,
        }}
        rows={mapper()}
        columns={columns}
        pageSize={25}
        autoHeight
        rowsPerPageOptions={[0, 1]}
        onRowClick={(newSelection) => {
          setUpdate(true);
          setSelection(newSelection.id);

          setQuery(false);
        }}
      />
    </Box>
  );
}

export default Users;
