/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import React, { useState } from 'react';
import { Grid, Box, Button, makeStyles, ButtonGroup } from '@material-ui/core';
import { HiUsers, HiOutlineSupport } from 'react-icons/hi';
import { IoEllipsisHorizontalCircleSharp } from 'react-icons/io5';
import { AiFillShop, AiOutlineControl } from 'react-icons/ai';
import { DiSnapSvg } from 'react-icons/di';
import { IoIosMail } from 'react-icons/io';
import TopBar from './TopBar';
import Users from './Users';
import Contact from './Contact';
import Support from './Support';
import Centers from './Centers';
import CentersRequests from './CentersRequests';
import Dashboard from './Dashboard';
// Styles
const useStyle = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.primary.light,
    height: '100vh',
    margin: 0,
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    position: 'fixed',
  },
  buttonGroup: {
    color: theme.palette.grey[300],
  },
  typography: {},
  elementContainer: {
    padding: theme.spacing(2),
    color: theme.palette.grey[700],
  },
}));

function Interface() {
  if (!sessionStorage.getItem('anchor')) {
    sessionStorage.setItem('anchor', 'Users');
  }
  const classes = useStyle();
  const [state, setState] = useState(null);
  const Switcher = () => {
    if (sessionStorage.getItem('anchor') === 'Contact') return <Contact />;
    if (sessionStorage.getItem('anchor') === 'Dashboard') return <Dashboard />;
    if (sessionStorage.getItem('anchor') === 'Support') return <Support />;
    if (sessionStorage.getItem('anchor') === 'Centers') return <Centers />;
    if (sessionStorage.getItem('anchor') === 'Users') return <Users />;
    if (sessionStorage.getItem('anchor') === 'CentersRequests') return <CentersRequests />;
    return <Dashboard />;
  };
  return (
    <Grid container>
      <Grid item sm={12}>
        <TopBar />
      </Grid>
      <Grid item sm={3} md={2}>
        {/* Navigation Start */}
        <Box className={classes.root} display="flex" alignItems="center">
          <ButtonGroup orientation="vertical" variant="text" fullWidth classes={{ root: classes.buttonGroup }}>
            <Button className={classes.elementContainer} startIcon={<AiOutlineControl />}
              onClick={() => {
                sessionStorage.setItem('anchor', 'Dashboard');
                setState('Dashboard');
              }}
            >
              لوحة الإدارة
            </Button>
            <Button
              className={classes.elementContainer}
              startIcon={<HiUsers />}
              onClick={() => {
                sessionStorage.setItem('anchor', 'Users');
                setState('Users');
              }}
            >
              المستخدمين
            </Button>
            <Button
              className={classes.elementContainer}
              startIcon={<IoEllipsisHorizontalCircleSharp />}
              onClick={() => {
                sessionStorage.setItem('anchor', 'CentersRequests');
                setState('CentersRequests');
              }}
            >
              طلبات المراكز
            </Button>
            <Button
              className={classes.elementContainer}
              startIcon={<IoEllipsisHorizontalCircleSharp />}
              onClick={() => {
                sessionStorage.setItem('anchor', 'Centers');
                setState('Centers');
              }}
            >
              قائمة المراكز
            </Button>
            {/* <Button className={classes.elementContainer} startIcon={<AiFillShop />} onClick={() => { }} disabled>
              المتجر
            </Button>
            <Button className={classes.elementContainer} startIcon={<DiSnapSvg />} onClick={() => { }} disabled>
              الدورات
            </Button> */}
            <Button
              className={classes.elementContainer}
              startIcon={<HiOutlineSupport />}
              onClick={() => {
                sessionStorage.setItem('anchor', 'Support');
                setState('Support');
              }}
            >
              الدعم الفني
            </Button>
            <Button
              className={classes.elementContainer}
              startIcon={<IoIosMail />}
              onClick={() => {
                sessionStorage.setItem('anchor', 'Contact');
                setState('Contact');
              }}
            >
              الرسائل
            </Button>
          </ButtonGroup>
        </Box>
        {/* Navigation End */}
      </Grid>
      <Grid item sm={9} md={10}>
        {Switcher()}
      </Grid>
    </Grid>
  );
}

export default Interface;
